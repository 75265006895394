import React from 'react'

// Third party
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import HalfCircle from 'components/elements/Vectors/HalfCircle'
import NavLink from 'components/shared/NavLink'

// Images
import LogoLight from 'img/logo-light.inline.svg'

// Hooks
import useLayout from 'hooks/useLayout'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const StyledBannerHome = styled.section`
  display: flex;
  justify-content: center;
`

const BannerWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.secondary};
  height: 690px;
  overflow: hidden;
  width: 100%;
  max-width: 1440px;

  @media (min-width: 992px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (min-width: 576px) {
    border-radius: 50px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -137px;
  right: -150px;

  @media (max-width: 991px) {
    & svg image {
      transform: translateZ(-1);
      opacity: 0.4;
    }
  }

  @media (min-width: 992px) {
  }

  @media (max-width: 991px) {
    right: -300px;
    & svg image {
      opacity: 0.4;
    }
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;
  color: ${(props) => props.theme.color.light};
  margin-top: 9rem;
`

const BannerHome: React.FC<BannerProps> = ({ fields }) => {
  const layout = useLayout()

  return (
    <StyledBannerHome>
      <BannerWrapper>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-6">
              <div className="my-5">
                <motion.div
                  initial={{ height: 'fit-content', y: 0 }}
                  animate={{
                    height: 0,
                    y: layout.isSticky ? -110 : 0,
                    type: 'spring',
                  }}
                  transition={{
                    duration: 0.2,
                  }}
                >
                  {/* <Brand to="/">
                    <LogoLight />
                  </Brand> */}
                </motion.div>
              </div>

              <Content content={fields.description} />
            </div>
          </div>
        </div>

        <BackgroundWrapper>
          <HalfCircle image={fields.image} />
        </BackgroundWrapper>
      </BannerWrapper>
    </StyledBannerHome>
  )
}

export default BannerHome
