import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerHome from 'components/flex/Banner/BannerHome'
import BannerDetail from 'components/flex/Banner/BannerDetail'
import BannerContact from 'components/flex/Banner/BannerContact'
import BannerCta from 'components/flex/Banner/BannerCta'

export interface BannerProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerProps> = ({ fields, location = {} }) => {
  const banners: BannersProps = {
    default: BannerDefault,
    home: BannerHome,
    detail: BannerDetail,
    cta: BannerCta,
    contact: BannerContact,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
