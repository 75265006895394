import React from 'react'

const BannerDefaultCircle = ({ image }: any) => (
  <svg
    width="1168"
    height="328"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <mask
      id="a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="0"
      width="1160"
      height="320"
    >
      <path
        // fillRule="evenodd"
        // clipRule="evenodd"
        d="M1163.3 0H812.042a430.06 430.06 0 0 1-227.901 65.349A430.06 430.06 0 0 1 356.24 0H4.978a679.91 679.91 0 0 0 95.504 119.622c266.696 266.697 700.622 266.697 967.318 0A679.951 679.951 0 0 0 1163.3 0Z"
        fill="#F4F4F4"
      />
    </mask>
    <g mask="url(#a)">
      <path fill="url(#b)" d="M-15-274h1195v799H-15z" />
    </g>
    <defs>
      <pattern
        id="b"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#c" transform="matrix(.00016 0 0 .00026 -.002 -.003)" />
      </pattern>
      <image
        id="c"
        width="6619"
        height="4329"
        xlinkHref={image?.localFile?.publicURL}
        style={{ overflow: 'visible' }}
      />
    </defs>
  </svg>
)

export default BannerDefaultCircle
