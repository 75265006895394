import React from 'react'

const HalfCircle = ({ image }: any) => (
  <svg
    width="900"
    height="795"
    viewBox="0 0 900 795"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g clipPath="url(#cliphalfcircle)">
      <mask
        id="maskhalfcircle"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="103"
        y="-597"
        width="1437"
        height="1437"
      >
        <path
          d="M1290.92 430.24C1207.78 474.638 1114.96 497.81 1020.71 497.695C867.892 497.526 721.379 436.743 613.319 328.682C505.258 220.622 444.475 74.1082 444.306 -78.7126C444.191 -172.962 467.363 -265.78 511.761 -348.917L263.807 -597C158.873 -444.548 102.789 -263.787 103.001 -78.7126C103.001 427.362 514.702 839.064 1020.78 839.064C1205.85 839.276 1386.61 783.192 1539.06 678.258L1290.92 430.24Z"
          fill="#FCCF14"
        />
      </mask>
      <g mask="url(#maskhalfcircle)">
        <rect
          width="994.134"
          height="1000"
          transform="matrix(-1 0 0 1 837.134 137)"
          fill="url(#patternhalfcircle)"
        />
      </g>
    </g>
    <defs>
      <pattern
        id="patternhalfcircle"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#halfcircle"
          transform="translate(-0.138015 -0.00735322) scale(0.000185938)"
        />
      </pattern>
      <clipPath id="cliphalfcircle">
        <rect
          width="1436"
          height="1417"
          fill="white"
          transform="translate(0 -622)"
        />
      </clipPath>
      <image
        id="halfcircle"
        width="6192"
        height="3500"
        xlinkHref={image?.localFile?.publicURL}
      />
    </defs>
  </svg>
)

export default HalfCircle
