import React from 'react'

// Third party
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Location from 'img/location.inline.svg'
import Phone from 'img/phone-secondary.inline.svg'
import Mail from 'img/mail.inline.svg'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'
import CustomMap from 'components/elements/Misc/CustomMap'

const StyledBannerContact = styled.section`
  display: flex;
  justify-content: center;
`

const BannerWrapper = styled.div`
  position: relative;
  height: 550px;
  width: 100%;
  max-width: 1170px;

  @media (min-width: 992px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (min-width: 576px) {
    border-radius: 50px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;
  color: ${(props) => props.theme.color.light};
`

const Row = styled.div`
  & a:hover {
    color: ${({ theme }) => theme.color.primary} !important;
    text-decoration: underline;
  }
`

const Col = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: 50px;

  @media (max-width: 1439px) {
    margin-bottom: 200px !important;
  }
`

const BannerContact: React.FC<BannerProps> = ({ fields }) => {
  const { location, phone, email, other }: any = fields.contact

  return (
    <StyledBannerContact className="mb-5 pb-5">
      <BannerWrapper>
        <div className="container h-100 ps-lg-1">
          <Row className="row h-100">
            <Col className="col-lg-10 ms-lg-3">
              <div className="row">
                <div className="col-lg-6">
                  <div className="p-lg-5 p-4">
                    <Content content={fields.description} className="mb-5" />
                    <div className="d-flex mb-4">
                      <Location />
                      <ParseContent content={location} className="ms-3" />
                    </div>
                    <div className="mb-4">
                      <div className="d-flex">
                        <Phone />
                        <a
                          className="ms-3 text-white"
                          href={phone.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {phone.title}
                        </a>
                      </div>
                      <div className="d-flex">
                        <Mail className="mt-1" />
                        <a
                          className="ms-3 text-white"
                          href={email.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {email.title}
                        </a>
                      </div>
                    </div>
                    <div>
                      <ParseContent content={other} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <CustomMap
          points={[
            { position: { lat: 52.17686066002134, lng: 5.4104951986515735 } },
          ]}
        />
      </BannerWrapper>
    </StyledBannerContact>
  )
}

export default BannerContact
