import React from 'react'

// Third party
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import BannerDefaultCircle from 'components/elements/Vectors/BannerDefaultCircle'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const StyledBannerDefault = styled.section`
  display: flex;
  justify-content: center;
`

const BannerWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.secondary};
  height: fit-content;
  overflow: hidden;
  width: 100%;
  max-width: 1440px;

  @media (min-width: 992px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (min-width: 576px) {
    border-radius: 50px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 320px;
  pointer-events: none;

  & svg > * {
    overflow: visible !important;
  }

  @media (min-width: 992px) {
    left: calc(50% - 585px);
  }

  @media (max-width: 991px) {
    left: -500px;
    & svg image {
      opacity: 0.4;
    }
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;
  color: ${(props) => props.theme.color.light};

  @media (min-width: 992px) {
    margin-top: 9rem;
  }

  @media (max-width: 991px) {
    margin-top: 3rem;
  }
`

const Row = styled.div`
  padding-bottom: 6rem;

  @media (min-width: 992px) {
    padding-top: 13rem;
  }
`

const BannerDefault: React.FC<BannerProps> = ({ fields }) => (
  <StyledBannerDefault className="mb-5 pb-lg-5">
    <BannerWrapper>
      <BackgroundWrapper>
        <BannerDefaultCircle image={fields.image} />
      </BackgroundWrapper>

      <div className="container h-100">
        <Row className="row h-100">
          <div className="col-lg-4 d-flex">
            <Content content={fields.description} />
          </div>
          <div className="col-lg-8 d-flex">
            <Content content={fields.seconddescription} />
          </div>
        </Row>
      </div>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
