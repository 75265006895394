/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react'

// Images
import pin from 'img/pin-secondary.svg'

// Third Party
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import styled from 'styled-components'
import getDistance from 'geolib/es/getDistance'

// Map options
// @ts-ignore
import GoogleMapsSettings from 'services/GoogleMapsSettings'

const StyledMarker = styled(Marker)`
  background-color: ${(props) => props.theme.color.secondary};
`

const StyledMap = styled(Map)`
  & > div {
    @media (min-width: 768px) {
      border-radius: 50% !important;
    }
  }

  & .gmnoprint {
    @media (min-width: 768px) {
      margin-left: 10.5rem !important;
      margin-top: 2rem !important;
    }
  }

  & .gm-bundled-control-on-bottom {
    @media (min-width: 768px) {
      margin-right: 14.5rem !important;
    }
  }

  & .gm-bundled-control-on-bottom {
    @media (min-width: 768px) {
      bottom: 140px !important;
      right: 200px !important;
    }
  }

  @media (max-width: 767px) {
    bottom: -300px !important;
    width: 100% !important;
    height: 300px !important;
    left: 0;
  }
`

export class MapContainer extends React.Component<any> {
  constructor(props: any) {
    super(props)
    this.state = {
      refresh: false,
    }
  }

  public mapLoaded(map: any) {
    map.setOptions({
      styles: GoogleMapsSettings,
    })
  }

  render() {
    const b = new this.props.google.maps.LatLngBounds()

    for (let i = 0; i < this.props.points.length; i += 1) {
      b.extend(this.props.points[i].position)
    }

    this.props.points.forEach((point: any) => {
      let range: number = 100000000
      this.props.points
        .filter((p: any) => p.name !== point.name)
        .forEach((otherPoint: any) => {
          const distance = getDistance(
            {
              latitude: point.position.lat,
              longitude: point.position.lng,
            },
            {
              latitude: otherPoint.position.lat,
              longitude: otherPoint.position.lng,
            },
            10
          )
          if (distance !== 0 && distance < range) {
            range = distance
          }
        })
      // eslint-disable-next-line no-param-reassign
      point.range = range
    })

    const calculatePinSize = (distance: number, length: number) => {
      if (length > 5) {
        if (distance < 100000) {
          return 10
        }

        if (distance < 1000000) {
          return 15
        }

        return 35
      }

      return 35
    }

    if (b === null) {
      return null
    }

    return (
      <>
        <StyledMap
          // @ts-ignore
          key={this.state.refresh ? `yes-map` : `no-map`}
          // @ts-ignore
          zoomControl
          maxZoom={20}
          zoom={12}
          scrollWheel={false}
          draggable
          streetViewControl={false}
          mapTypeControl
          panControl
          fullscreenControl={false}
          google={this.props.google}
          initialCenter={{
            lat: 52.17686066002134,
            lng: 5.4104951986515735,
          }}
          containerStyle={{
            position: 'absolute',
            bottom: '-20px',
            right: '-7%',
            width: '500px',
            height: '500px',
          }}
          onReady={(mapProps, map) => this.mapLoaded(map)}
          bounds={b}
        >
          {this.props.points.map((point: any) => (
            <StyledMarker
              // @ts-ignore
              title={point.title}
              name={point.name}
              position={point.position}
              icon={{
                url: pin,
                // @ts-ignore
                scaledSize: new google.maps.Size(
                  calculatePinSize(point.range, this.props.points.length),
                  calculatePinSize(point.range, this.props.points.length)
                ),
              }}
              onClick={() => {
                window.open(
                  'https://www.google.com/maps/dir//Printerweg+22+3821+AD+Amersfoort/@52.1767569,5.4104921,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c646f384d5113b:0x86a7a39d8fa87360!2m2!1d5.4104921!2d52.1767569'
                )
              }}
            />
          ))}
        </StyledMap>
      </>
    )
  }
}

export default GoogleApiWrapper(() => ({
  apiKey: 'AIzaSyAmr5r8d6LKmu418qfE8hwGSk30of_yRRA',
  language: 'nl-NL',
  LoadingContainer: 'h2',

  // @ts-ignore
}))(MapContainer)
