import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const StyledBannerDetail = styled.section`
  display: flex;
  justify-content: center;
`

const BannerWrapper = styled.div`
  position: relative;
  min-height: 550px;
  width: 100%;
  max-width: 1140px;

  @media (min-width: 992px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (min-width: 576px) {
    border-radius: 50px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;
  color: ${(props) => props.theme.color.light};

  @media (min-width: 992px) {
    padding-right: 15rem;
  }
  @media (max-width: 991px) {
    padding: 1rem;
  }
`

const Row = styled.div`
  & a:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: underline;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  max-width: 564px;
  z-index: 2;
  @media (min-width: 992px) {
    right: -80px;
    top: calc(50% - 200px);
  }
  @media (max-width: 991px) {
    right: -120px;
    bottom: -50px;
  }
  @media (max-width: 767px) {
    right: -225px;
    bottom: -100px;
  }
  @media (max-width: 575px) {
    right: -200px;
    bottom: -110px;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 991px) {
    width: 80%;
    height: 70%;
  }
  @media (max-width: 767px) {
    width: 60%;
    height: 50%;
  }
  @media (max-width: 575px) {
    width: 40%;
    height: 30%;
  }
`

const Col = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: 50px;
`

const BannerDetail: React.FC<BannerProps> = ({ fields }) => (
  <StyledBannerDetail className="mb-5 pb-5">
    <BannerWrapper>
      <div className="container h-100 ps-lg-1">
        <Row className="row h-100">
          <Col className="col-lg-10">
            <div className="p-lg-5">
              <div className="p-lg-4">
                <Content content={fields.description} className="mb-5" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ImageWrapper>
        <Image image={fields.image} alt="" />
      </ImageWrapper>
    </BannerWrapper>
  </StyledBannerDetail>
)

export default BannerDetail
