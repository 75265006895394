import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const StyledBannerCta = styled.section`
  display: flex;
  justify-content: center;
`

const BannerWrapper = styled.div`
  position: relative;
  min-height: 700px;
  width: 100%;
  max-width: 1140px;

  @media (min-width: 992px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (min-width: 576px) {
    border-radius: 50px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 4;
  color: ${(props) => props.theme.color.light};

  @media (min-width: 992px) {
    padding-right: 12rem;
  }

  @media (max-width: 991.98px) {
    padding: 2rem;
  }

  @media (max-width: 575.98px) {
    padding: 1rem;
  }
`

const Row = styled.div`
  & a:hover {
    color: ${({ theme }) => theme.color.primary} !important;
    text-decoration: underline;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  right: 50px;
  z-index: 2;

  & img {
    object-fit: contain !important;
    max-width: 372px;
    max-height: 470px;
  }

  @media (min-width: 992px) {
    top: calc(50% - 280px);
  }

  @media (max-width: 991.98px) {
    bottom: 40px;
  }

  @media (max-width: 767.98px) {
    bottom: 200px;
  }
`

const Cta = styled.div`
  & a:hover {
    color: ${({ theme }) => theme.color.secondary} !important;
  }
`

const CtaImage = styled(Plaatjie)`
  width: 127px;
  height: 127px;
  border-radius: 50%;

  @media (max-width: 400.98px) {
    width: 100%;
    height: 100%;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 991.98px) {
    width: 200px;
  }

  @media (max-width: 767.98px) {
    width: 100px;
    opacity: 0.5;
  }
`

const Col = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: 50px;
  z-index: 2;
`

const BannerCta: React.FC<BannerProps> = ({ fields }) => (
  <StyledBannerCta className="mb-5 pb-5">
    <BannerWrapper>
      <div className="container ps-lg-1 h-100">
        <Row className="row h-100">
          <Col className="position-relative col-lg-10">
            <div className="p-lg-5">
              <div className="p-lg-4">
                <Content content={fields.description} className="mb-5" />
                <Cta className="d-flex pb-5 pb-lg-0">
                  <CtaImage image={fields.cta?.image} alt="" />
                  <ParseContent
                    content={fields.cta?.description}
                    className="ms-5"
                  />
                </Cta>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ImageWrapper>
        <Image image={fields.image} alt="" />
      </ImageWrapper>
    </BannerWrapper>
  </StyledBannerCta>
)

export default BannerCta
